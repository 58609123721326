<template>
  <v-container>
      <v-row>
          <v-col md="12" sm="12">
            <v-card
                class="mx-auto"
                max-width="100%"
                outlined
                link
                to="/data_entry/entry_types"
                style="padding-top:3px"
            >
            <v-list-item >

            <v-list-item-avatar
                tile
                size="90"
                color="white"
            >
            <v-img max-width="100%" src="@/assets/report.png"></v-img>
            
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="text-overline mb-4">
                Reporting Project
                </div>
                <v-list-item-title class="text-h6 mb-1">
                    Digital Technology Institute
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>         
          <v-col md="3" sm="4">
            <v-card
                class="mx-auto"
                max-width="344"
                outlined
                link
                to=""
            >
            <v-list-item >

            <v-list-item-avatar
                tile
                size="90"
                color="white"
            >
            <v-img max-width="100%" src="@/assets/clipboard.png"></v-img>
            
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="text-overline mb-4">
                Details
                </div>
                <v-list-item-title class="text-h6 mb-1">
                Add Project Details
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>       
          <v-col md="3" sm="4">
            <v-card
                class="mx-auto"
                max-width="344"
                outlined
                link
                to=""
            >
            <v-list-item >

            <v-list-item-avatar
                tile
                size="90"
                color="white"
            >
            <v-img max-width="100%" src="@/assets/business-plan.png"></v-img>
            
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="text-overline mb-4">
                Planning
                </div>
                <v-list-item-title class="text-h6 mb-1">
                Implementation Plan
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>
          <v-col md="3" sm="4">
            <v-card
                class="mx-auto"
                max-width="344"
                outlined
                link
                to=""
            >
            <v-list-item >

            <v-list-item-avatar
                tile
                size="90"
                color="white"
            >
            <v-img max-width="100%" src="@/assets/project-launch.png"></v-img>
            
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="text-overline mb-4">
                Progress
                </div>
                <v-list-item-title class="text-h6 mb-1">
                Progress Reporting
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>
          <v-col md="3" sm="4">
            <v-card
                class="mx-auto"
                max-width="344"
                outlined
                link
                to=""
            >
            <v-list-item >

            <v-list-item-avatar
                tile
                size="90"
                color="white"
            >
            <v-img max-width="100%" src="@/assets/budget.png"></v-img>
            
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="text-overline mb-4">
                Finance
                </div>
                <v-list-item-title class="text-h6 mb-1">
                Financial Reporting
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>
          <v-col md="3" sm="4">
            <v-card
                class="mx-auto"
                max-width="344"
                outlined
                link
                to=""
            >
            <v-list-item >

            <v-list-item-avatar
                tile
                size="90"
                color="white"
            >
            <v-img max-width="100%" src="@/assets/failure.png"></v-img>
            
            </v-list-item-avatar>
            <v-list-item-content>
                <div class="text-overline mb-4">
                Other
                </div>
                <v-list-item-title class="text-h6 mb-1">
                Challenge & Remarks
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>
            </v-card>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>

</style>